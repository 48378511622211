import React from "react";
import "../styles/contact.css";
import SectionHeader from "../components/SectionHeader";

function Contact() {
  return (
    <div className="contactContainer" id="contact">
      <SectionHeader sectionHeader="Contact Us" />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <h1 className="title">A-CORD SEALS</h1>
            <h4>Mechanical Shaft Sealing Hub</h4>
            <h5 className="caption">Your attention, Our Co-ordination</h5>
            <span
              style={{
                backgroundColor: "white",
                display: "flex",
                border: "1px solid white",
              }}
            ></span>
            <br />
            <p className="address">
              H.O. & Factory: Plot No. 657, Rajeshwari Compound, Nr. Sonale Police
              Station, Sonale Village, Bhiwandi, Dist - Thane 421 302, Mob: +91
              9604028400 / +91 8976285856 / +91 9322778733
            </p>
          </div>
          <div className="col-lg-4 col-md-12">
            <i className="fa fa-envelope-o fa-icon" aria-hidden="true">
              {" "}
              Emails
            </i>
            <br /> <br />
            <div>
              <p className="email">info@acordseals.com</p>
              <p className="email">support@acordseals.com</p>
              <p className="email">sales@acordseals.com</p>
              <p className="email">acordseals@gmail.com</p>
            </div>
            <div>
              <a className="socialLinks" rel="noopener noreferrer" href="https://facebook.com" target="_blank"> <i className="fa fa-facebook fa-icon2" data-toggle="tooltip" data-placement="top" title="Facebook" aria-hidden="true"></i></a>
              <a className="socialLinks" rel="noopener noreferrer" href="https://www.instagram.com/acord_seals" target="_blank"> <i className="fa fa-instagram fa-icon2" data-toggle="tooltip" data-placement="top" title="Instagram" aria-hidden="true"></i></a>
              <a className="socialLinks" rel="noopener noreferrer" href="https://www.linkedin.com/" target="_blank"> <i className="fa fa-linkedin fa-icon2" data-toggle="tooltip" data-placement="top" title="Linkedin" aria-hidden="true"></i></a>
              <a className="socialLinks" rel="noopener noreferrer" href="/catelogue" target="_blank"> <i className="fa fa-download fa-icon2" data-toggle="tooltip" data-placement="top" title="Catalogue" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
