import React from "react";
import ServiceItem from "../components/ServiceItem";
import "../styles/services.css";
import  {service_data}  from "../constants/data";
import SectionHeader from "../components/SectionHeader"

function Services() {
  const renderServices = () => {
    return service_data.map((service) => {
      return (
        <ServiceItem
          key={service.id}
          serviceName={service.service_name}
          imageName={service.image_name}
          serviceText={service.service_text}
        />
      );
    });
  };
  return (
    <div>
      <div className="serviceContainer" id="services">
        <SectionHeader sectionHeader="Services" />
        <div className="serviceArea">{renderServices()}</div>
      </div>
    </div>
  );
}

export default Services;
