import React from "react";
import NavBar from "../components/NavBar";
import CarouselItem from "../components/CarouselItem";
import Services from "./Services";
import Products from "./Products";
// import WhyUS from "./WhyUs";
import About from "./About";
import Contact from "./Contact";
import Footer from "../components/Footer"


function App() {
  return (
    <div className="App">
      <NavBar />
      <CarouselItem />
      <Products />
      <Services />
      {/* <WhyUS /> */}
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
