import React from "react";
import "../styles/gallery.css";
import SectionHeader from "../components/SectionHeader";
import GalleryItem from "../components/GalleryItem";
import { gallery_data } from "../constants/data";
import { Link } from "react-router-dom";

class Gallery extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {category: 'single_spring_seals'};
    // this.handleChange = this.handleChange.bind(this);
  }

   renderGalleryProducts = () => {
    return gallery_data[this.state.category].map((product) => {
      return (
        <GalleryItem
          key={product.id}
          productName={product.product_name}
          imageName={product.image_name}
          applications={product.applications}
          features={product.features}
        />
      );
    });
  };
  render(){
    return (
      <div className="galleryDiv">
        <SectionHeader sectionHeader="Gallery" />
        <div className="container galleryContainer">
          <hr className="mt-2" />
          <Link to="/" className="back-btn">
            Go Back
          </Link>
          <div className="categorySelect">
            <select className="custom-select" value={this.state.category} onChange={(event)=>{
              this.setState({category: event.target.value});
            }}>
              {/* <option >Select a Category</option> */}
              <option defaultValue value="single_spring_seals">Single Spring Seals</option>
              <option value="bellow_seals">Bellow Seals</option>
              <option value="multispring_seals">Multispring Seals</option>
              <option value="special_seals">Special Seals</option>
              <option value="catridge_seals">Catridge Seals</option>
              {/* <option value="filters">Filters</option> */}
              <option value="agitator_seals">Agitator Seals</option>
              <option value="thermosypon_system">Thermosypon System</option>
            </select>
            <div className="row text-center text-lg-left galleryItemContainer">
              <div className="col-lg-3 col-md-4 col-6 galleryDiv">
                {this.renderGalleryProducts()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
