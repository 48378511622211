import React from "react";
import "../styles/carouselitem.css";

function CarouselItem() {
  return (
    <div id="carouselItem">
      <span id="title" className="carouselContent">A-CORD SEALS</span>
      <span className="caption1 carouselContentCaption">Mechanical Shaft Sealing Hub</span>
      <span className="caption2 carouselContentCaption">Your attention, Our Co-ordination</span>
      <span className="caption1 carouselContentCaption">An ISO 9001:2015 Certified Company</span>  
    </div>
  );
}

export default CarouselItem;
