import React from "react";
import ProductItem from "../components/ProductItem";
import "../styles/products.css";
import { product_data } from "../constants/data";
import SectionHeader from "../components/SectionHeader";
import { Link } from "react-router-dom";

function Products() {
  const renderProducts = () => {
    return product_data.map((product) => {
      return (
        <ProductItem
          key={product.id}
          productName={product.product_name}
          imageName={product.image_name}
          applications={product.applications}
        />
      );
    });
  };
  return (
    <div>
      <div className="productContainer" id="products">
        <SectionHeader sectionHeader="Products" />
        <div className="productArea">{renderProducts()}</div>
        <Link to="/gallery" className="view-more-btn">View More</Link>
      </div>
    </div>
  );
}

export default Products;
