const service_data = [
  {
    id: 1,
    service_name: "Repairing/Reconditioning",
    image_name: "reconditioning.png",
    service_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
  },
  {
    id: 2,
    service_name: "Installation",
    image_name: "installation.jpg",
    service_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
  },
  {
    id: 3,
    service_name: "Site Visit",
    image_name: "site_visit.png",
    service_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
  },
];

const product_data = [
  {
    id: 1,
    product_name: "AC-S01",
    image_name: "product1.jpeg",
    product_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
    applications: [
      "Water pumps",
      "Chemical Industry",
      "Oil Application",
      "Pharmaceutical",
    ],
  },
  {
    id: 2,
    product_name: "AC-S02",
    image_name: "product2.jpeg",
    product_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
    applications: [
      "Water pumps",
      "Chemical Industry",
      "Oil Application",
      "Pharmaceutical",
    ],
  },
  {
    id: 3,
    product_name: "AC-S03",
    image_name: "product3.png",
    product_text: ` Some quick example text to build on the card title and make up the
            bulk of the card's content.
            Some quick example text to build on the card title and make up the
            bulk of the card's content.`,
    applications: [
      "Water pumps",
      "Chemical Industry",
      "Oil Application",
      "Pharmaceutical",
    ],
  },
  // {
  //   id: 4,
  //   product_name: "AC-S04",
  //   image_name: "product4.jpeg",
  //   product_text: ` Some quick example text to build on the card title and make up the
  //           bulk of the card's content.
  //           Some quick example text to build on the card title and make up the
  //           bulk of the card's content.`,
  //   applications: ["Water pumps", "Chemical Industry", "Oil Application", "Pharmaceutical"]
  // },
  // {
  //   id: 5,
  //   product_name: "AC-S05",
  //   image_name: "product1.jpeg",
  //   product_text: ` Some quick example text to build on the card title and make up the
  //           bulk of the card's content.
  //           Some quick example text to build on the card title and make up the
  //           bulk of the card's content.`,
  //   applications: ["Water pumps", "Chemical Industry", "Oil Application", "Pharmaceutical"]
  // },
];

const gallery_data = {
  single_spring_seals: [
    {
      id: 1,
      product_name: "AC-S01",
      image_name: "single_spring_seals_1.png",
      features: ["Single Acting", "Inside Mounted", "Unbalanced", "Independent of direct of rotation"],
      applications: [
        "Water pumps",
        "Chemical Industry",
        "Oil Application",
        "Pharmaceutical",
      ],
    },
    {
      id: 2,
      product_name: "AC-S02",
      image_name: "single_spring_seals_2.png",
      features: ["Single Acting", "For stepped Shaft", "Balanced", "Wave spring design"],
      applications: [
        "Chemical Industry",
        "Oil Application",
        "Pulp & Paper",
        "Food Industry",
      ],
    },
    {
      id: 3,
      product_name: "AC-S03",
      image_name: "single_spring_seals_3.png",
      features: ["Single Acting", "Inside Mounted1", "Unbalanced", "Independent of direct of rotation"],
      applications: [
        "Slurry Application",
        "Petroleum Refinery",
        "General Chemicals",
        "Fertilizers",
      ],
    },
  ],
  "bellow_seals":[
    {
      id: 1,
      product_name: "AC-MB01",
      image_name: "Bellow_Seals_1.png",
      features: ["Single Acting", "Inside Mounted", "Balanced", "Independent of direct of rotation"],
      applications: [
        "Petrochemicals & Refinery medias",
        "Heat transfer fluid",
        "Pulp & Paper",
      ],
    },
    {
      id: 2,
      product_name: "BC-MB02",
      image_name: "Bellow_Seals_2.png",
      features: ["Single Acting", "Inside Mounted", "Balanced", "Independent of direct of rotation"],
      applications: [
        "Petrochemicals & Refinery medias",
        "Heat transfer fluid",
        "Pulp & Paper",
      ],
    },
    {
      id: 3,
      product_name: "BC-TB03",
      image_name: "Bellow_Seals_3.png",
      features: ["Single Acting", "External Mounted", "Bio Directional", "For plain Shaft"],
      applications: [
        "Corrosive & critical application",
        "Concentrated Acid",
        "Paint Industry",
        "Alkalies",
        "Slated",
      ],
    },
  ],
  "multispring_seals":[
    {
      id: 1,
      product_name: "AC-MS01",
      image_name: "Multispring_Seals_1.png",
      features: ["Single Acting", "Inside Mounted", "Balanced", "Independent of direct of rotation"],
      applications: [
        "Food Industry",
        "Chemical Industry",
        "Oil Application",
        "Pharmaceutical",
      ],
    },
    {
      id: 2,
      product_name: "AC-MS02",
      image_name: "Multispring_Seals_2.png",
      features: ["Single Acting", "Inside Mounted", "Unbalanced", "Independent of direct of rotation"],
      applications: [
        "Petrochemicals",
        "Chemical Industry",
        "Oil Application",
        "Light Hydrocarbons",
      ],
    },
    {
      id: 3,
      product_name: "AC-MS03",
      image_name: "Multispring_Seals_3.png",
      features: ["Single Acting", "Inside Mounted", "Balanced", "Independent of direct of rotation"],
      applications: [
        "Sugar Industry",
        "Pulp & Paper industry",
        "General Chemicals",
        "Solvent extraction",
      ],
    },
  ],
  "special_seals":[
    {
      id: 1,
      product_name: "AC-GF01",
      image_name: "Special_Seals_1.png",
      features: ["Single Acting", "Inside Mounted", "Balanced"],
      applications: [
        "Utility",
        "ETP",
        "Paint Industry",
        "Petrochemical Industry",
      ],
    },
    {
      id: 2,
      product_name: "AC-MG01",
      image_name: "Special_Seals_2.png",
      features: ["Single End", "Unbalanced", "Single Seal"],
      applications: [
        "Oil, Dairy, Water",
        "Chemical Industry",
        "Pulp & Paper Industry",
      ],
    },
    {
      id: 3,
      product_name: "AC-GL01",
      image_name: "Special_Seals_3.png",
      features: ["Single Acting", "Inside Mounted", "Balanced"],
      applications: [
        "Petroleum refinery",
        "General Chemicals",
        "Fertilizers",
        "Pharmaceutical Industry",
      ],
    },
  ],
  "catridge_seals":[
    {
      id: 1,
      product_name: "AC-CTS01",
      image_name: "Catridge_Seals_1.png",
      features: ["Single Acting", "Balanced"],
      applications: [
        "Process Industry",
        "Chemical Industry",
        "Pulp & Paper",
        "Sugar Industry",
      ],
    },
    {
      id: 2,
      product_name: "AC-CTM01",
      image_name: "Catridge_Seals_2.png",
      features: ["Double Acting", "Balanced"],
      applications: [
        "Process Industry",
        "Chemical Industry",
        "Pulp & Paper",
        "Sugar Industry",
      ],
    },
    {
      id: 3,
      product_name: "AC-CTMB01",
      image_name: "Catridge_Seals_3.png",
      features: ["Double Acting", "Metal Bellow"],
      applications: [
        "Process Industry",
        "Chemical Industry",
        "Pulp & Paper",
        "Sugar Industry",
      ],
    },
  ],
  // "filters":[
  //   {
  //     id: 1,
  //     product_name: "BC-MS01",
  //     image_name: "gallery4.jpg",
  //     features: ["Single Acting", "Inside Mounted", "Balanced"],
  //     applications: [
  //       "Utility",
  //       "ETP",
  //       "Paint Industry",
  //       "Petrochemical Industry",
  //     ],
  //   },
  //   {
  //     id: 2,
  //     product_name: "BC-MS01",
  //     image_name: "gallery7.jpg",
  //     features: ["Single Acting", "Inside Mounted", "Balanced"],
  //     applications: [
  //       "Utility",
  //       "ETP",
  //       "Paint Industry",
  //       "Petrochemical Industry",
  //     ],
  //   },
  // ],
  "agitator_seals":[
    {
      id: 1,
      product_name: "AC-AG01",
      image_name: "Agiator_Seals_1.png",
      features: [
        "Glass Lined Reactor",
        "Double Balance Seal",
        "PSingle Balance Seal",
        "Dry Running Seal",
        "Agitator Nutsche Filter",
        "High Pressure Seal",
        "Rotary Union / Rotary Joint",
      ],
      application: [],
    },
  ],
  "thermosypon_system":[
    {
      id: 1,
      product_name: "AC-MS01",
      image_name: "Thermosyphon_System_1.png",
      features: [
        "Capacity: Upto 12 ltrs.",
        "Pressure: Upto 10 Bar",
        "Temperature: 170 C",
        "Cooling water ow rate",
      ],
      application: [],
    },
  ],
};

export { service_data, product_data, gallery_data };
