import React from "react";

function SectionHeader({ sectionHeader }) {
  return (
    <div className="sectionHeaderDiv">
      <h3 className="sectionHeader"> --------- {sectionHeader} --------- </h3>
    </div>
  );
}

export default SectionHeader;
