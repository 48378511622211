import React from "react";

function ProductItem({ productName, imageName, applications }) {
  function renderApplication() {
    return applications.map((application, index) => {
      return <h3 key={index} style={{color:"white"}}>{application}</h3>;
    });
  }
  return (
    // <div className="card border-success mb-3 productCard">
    //   <div className="card-body text-primary productBody">
    //     <div className="productCardText">
    //       {/* <div className="productFeatureTab">Features</div>
    //         <div className="productFeatureText">sdf</div> */}
    //       <img
    //         className="productImage"
    //         src={require(`../assets/images/products/${imageName}`)}
    //         alt="product"
    //       />
    //     </div>
    //     <div className="card-header">
    //       <h4 className="productName">{productName}</h4>
    //     </div>
    //   </div>
    //   <div className="flip-card-back">
    //     <h1>John Doe</h1>
    //     <p>Architect & Engineer</p>
    //     <p>We love that guy</p>
    //   </div>
    // </div>

    <div className="card-container">
      <div className="front">
        <img
          className="productImage"
          src={require(`../assets/images/products/${imageName}`)}
          alt="product"
        />
        <div style={{ margin: "auto" }}>
          <span
            style={{ fontSize: "25px", fontWeight: "bold", color: "white" }}
          >
            {productName}
          </span>
        </div>
      </div>
      <div className="back">
        <div className="applicationDiv">
          <h3
            style={{
              marginBottom: "50px",
              marginTop: "10px",
              color: "yellow",
              fontWeight: "bold",
            }}
          >
            Applications
          </h3>
          {renderApplication()}
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
