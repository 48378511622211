import React from "react";

function ServiceItem({ serviceName, imageName, serviceText }) {
  return (
    <div className="App">
      <div className="card border-warning mb-3 serviceCard">
        <img
          className="card-img-top service-img"
          src={require(`../assets/images/${imageName}`)}
          alt="Card cap"
        />
        <br />
        <h5 className="sectionSubHeader">{serviceName}</h5>
        <div className="card-body">
          <p className="card-text sectionText">{serviceText}</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
