import React from 'react';
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import './styles/index.css';
import App from "./views/App"
import Gallery from "./views/Gallery"
import Catelogue from "./views/Catelogue"


import * as serviceWorker from './serviceWorker';

const routes = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/catelogue" component={Catelogue} />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(routes, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();






