import React from "react";

function Catelogue() {
  return (
    <div>
      <div style={{ height: "100vh" }}>
        <embed
          src={require(`../assets/A-CORD_SEALS.pdf`)}
          type="application/pdf"
          style={{width:"100%", height:"100vh"}}
        />
      </div>
    </div>
  );
}

export default Catelogue;
