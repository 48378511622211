import React from "react";
function GallerylItem({ imageName, productName, features = [], applications = [] }) {
  const renderLiItems = (data, index) => {
    return data.map((liElement) => {
      return <li key={liElement}>{liElement}</li>;
    });
  };

  return (
    <div id="gallerylItem">
      <span>
        <img
          className="img-fluid img-thumbnail gallery-img"
          src={require(`../assets/images/gallery/${imageName}`)}
          alt=""
        />
        <div>
          <h3 className="galleryProductTitle">{productName}</h3>
          <div>
            <div className="galleryProductSpecsDiv">
              <span className="galleryProductSpecsTitle">Features: </span>
              <ul className="galleryProductSpecsDetails">
                {renderLiItems(features)}
              </ul>
            </div>
            <div className="galleryProductSpecsDiv">
              <span className="galleryProductSpecsTitle">Applications: </span>
              <ul className="galleryProductSpecsDetails">
                {renderLiItems(applications)}
              </ul>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
}

export default GallerylItem;
