import React from "react";
import "../styles/navbar.css";

function NavBar() {
  return (
    <div>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-color">
        <img id="logo" src={require("../assets/images/logo1.png")} alt="logo" />
        <a className="navbar-brand" href="/#">
          &nbsp;&nbsp;A-CORD SEALS
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <form className="form-inline my-2 my-lg-0">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item active nav-li">
                <a className="nav-link" href="/#">
                  Home
                </a>
              </li>
              <li className="nav-item active nav-li">
                <a className="nav-link" href="/#products">
                  Products
                </a>
              </li>
              <li className="nav-item active nav-li">
                <a className="nav-link" href="/#services">
                  Services
                </a>
              </li>
              {/* <li className="nav-item active nav-li">
                <a className="nav-link" href="/#enquiry">
                  Enquiry
                </a>
              </li> */}
              <li className="nav-item active nav-li">
                <a className="nav-link" href="/#aboutUs">
                  About Us
                </a>
              </li>
              <li className="nav-item active nav-li">
                <a className="nav-link" href="/#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </form>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
