import React from "react";
import "../styles/about.css";
import SectionHeader from "../components/SectionHeader";

function About() {
  return (
    <div className="aboutContainer" id="aboutUs">
      <SectionHeader sectionHeader="About Us" />
      <div className="container">
        <div className="row">
          <div className="col-4 img-class">
            <img
              className="aboutImage"
              src={require(`../assets/images/catridge_seal.png`)}
              alt="product"
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <h3>A-Cord Seals</h3>
            <p>
              We introduce ourselves as leading Manufacturing Company of
              complete range of Mechanical shaft Seals and Sealing
              components with innovative technology for Pumps, Agitators,
              Mixers, Vacuum Pumps, Blenders, Reactors, Blowers, Pharmaceutical
              Machinery and Rotating Equipment’s in Thane dist, Mumbai.
            </p>
            <p>We are
              certified to the ISO 9001:2008 for quality management systems and
              have successfully implemented environment management systems and
              occupational management systems Our product range includes Pusher
              Seals, Non-Pusher Seals, Rubber Bellow Seals, PTFE (Teflon) Bellow
              Seals, Metal Bellows Seals, Dry Running Seals, Agitator Seals, and
              Seal Support Systems,  Our seals associated products mainly for
              the Oil & gas, Solvent Extractions, Petro Chemicals, Fertilizers,
              Chemical, Pulp & Paper, Drugs & Pharmaceuticals, Food Processing,
              Textile, Cement, Sugar, Water Treatments, Soap and many more
              industrial applications.
             </p>
             <p>Our company is one of the efficient
              manufacturers of Mechanical Seals which caters all types of
              industries mainly occupied in India and we are providing essential
              services and solutions with our high excellence of standard.
              “A-CORD SEALS” specialize in providing solutions to all types of
              leakages by manufacturing Mechanical shaft Seals and Sealing
              Components as per customer’s drawings, samples or requirements. We
              also specialize in refurbishment all types of Mechanical Seals &
              total satisfaction for customers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <h3>UNIQUE SPECIALTIES OF “A-CORD”</h3>
            <p>
              • Ensured to longer life & durability design & development <br />•
              We help in developing innovative products and upgrading the
              existing equipment’s to latest standards with short cycle times{" "}
              <br />
              • Team of dedicated and technically sound staffs to execute every
              task efficiently <br />• Development of special seals as per
              sample and application wise <br />
              • Excellent quality & quick delivery as per customer’s
              requirements <br />
              • Ensured to longer life & durability design & development <br />
              • Enabling Engineering Information Management solutions in process
              industry <br />
              • Experience of serving big industries <br />
              • We repair all types of API and Non-API mechanical seals.
            </p>
          </div>
          <div className="col-4 img-class">
            <img
              className="aboutImage"
              src={require(`../assets/images/pump.png`)}
              alt="product"
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4">
            <img
              className="aboutImage"
              src={require(`../assets/images/logo.png`)}
              alt="product"
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <h3>Why Us?</h3>
            <p>
              • Quality assurance <br />
              • Quick service <br />
              • Premium quality of the products <br />
              • On time delivery <br />
              • Reasonable price <br />
              • Experienced workforce <br />
              • Robust infrastructure <br />
              • Advanced Designs <br />
              • Experienced Work Force
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
