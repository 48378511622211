import React from "react";

function Footer() {
  return (
    <div style={{margin:"1.5%"}}>
      <h3 className="footer"> &copy;A-Cord Seals 2020 </h3>
    </div>
  );
}

export default Footer;
